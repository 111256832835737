/*!
=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import axios from "axios";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col
} from "reactstrap";

// reactstrap modal components
import {Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

class Newchatwidget extends React.Component {
  constructor(props) {
    super(props);
	
    this.state = {
		name: '', 
		email: '', 
		whatsapp: '',
		modal: false,
		modalContent: ''
	};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
	this.toggleModal = this.toggleModal.bind(this);
	this.showModal = this.showModal.bind(this);
	this.hideModal = this.hideModal.bind(this);
  }
  
  showModal(content) {
	this.setState({
      modalContent: content, 
	  modal: true
    });
  }
  
  hideModal() {
    this.setState({
      modal: false
    });
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }
  
  handleChange(event) {
    const fieldName = event.target.name;
	this.setState({[fieldName]: event.target.value});
  }
  
  handleSubmit(event) {
    event.preventDefault();  
	this.doSubmit();
  }

  doSubmit() {	  
	const URL_TO_POST = "https://zapbot.isoftsolutions.com.br/core/registerWidget.php";
	
	/*fetch(URL_TO_POST, {
			method: 'post',
			body: new FormData(document.querySelector('#newChatForm'))*/

	axios.post(URL_TO_POST, new FormData(document.querySelector('#newChatForm')))
		.then(res => {
			var data = res.data;
		    console.log(data);
			
			if (data.error) {
				alert(data.error);
			} 
			else {		
				var text = '<script async name="izap" src="https://zapbot.isoftsolutions.com.br/widget/dist/js/izap-floating.js?id=' + data.wid + '"></script>';
			
				this.showModal(text);
			}
		})
		.catch(err => {
		    console.log(err);
			if (err.response) {  // client received an error response (5xx, 4xx)
				//alert(err.response.data);
			} else if (err.request) {  // client never received a response, or request never left
				//alert(err.request.data);
			} else {
			}
		});
  }
  
  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Informe os dados abaixo para criar o seu botão</small>
              </div>
              <Form role="form" name="newChatForm" id="newChatForm" onSubmit={this.handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-name-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input name="name" value={this.state.name} onChange={this.handleChange} placeholder="Nome da Empresa" type="text" autoComplete="new-name" />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-celphone-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input name="whatsapp" value={this.state.whatsapp} onChange={this.handleChange} placeholder="DDD e Celular com Whatsapp" type="whatsapp" autoComplete="new-whatsapp"/>
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input name="email" value={this.state.email} onChange={this.handleChange} placeholder="E-mail" type="email" autoComplete="new-email"/>
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Criar Botão de Contato por Whatsapp
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>	
		
        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalHeader>Instruções</ModalHeader>
          <ModalBody>
			<p>Seu botão de Whatsapp foi criado com sucesso. Siga as instruções abaixo para colocá-lo no seu site.</p>
			<p>Copie o código abaixo e o insira antes do fechamento da tag &lt;/body&gt; da sua página.</p>
			<code>{this.state.modalContent}</code>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.toggleModal}>OK</Button>
          </ModalFooter>
        </Modal>
		
      </>
    );
  }
}

export default Newchatwidget;